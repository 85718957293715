<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="closeModal"
        width="800px"
        top="20px"
        :close-on-click-modal="false"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('clients.add_address') }}
                </p>
            </div>
        </span>

        <div class="mb-14 mt-8 px-16">
            <el-form ref="form" label-position="top" :model="addressData" :rules="rules">
                <el-form-item :label="$t('common.google_map_search')">
                    <AutoComplete @placeFound="placeFound" />
                </el-form-item>
                <div class="grid grid-cols-2 gap-8">
                    <el-form-item :label="$t('clients.title')">
                        <el-input v-model="addressData.title" />
                    </el-form-item>
                    <el-form-item v-if="userIsBuildingCompany()" :label="$t('clients.status')">
                        <el-select v-model="addressData.status" class="w-full">
                            <el-option value="ongoing" :label="$t('clients.ongoing')" />
                            <el-option value="completed" :label="$t('clients.completed')" />
                            <el-option value="not_finished" :label="$t('clients.not_finished')" />
                            <el-option value="future_work" :label="$t('clients.future_work')" />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8">
                    <el-form-item label="CO">
                        <el-input v-model="addressData.co" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.street')" prop="street">
                        <el-input v-model="addressData.street" />
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8">
                    <el-form-item :label="$t('clients.city')" prop="city">
                        <el-input v-model="addressData.city" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.post_code')" prop="post_code">
                        <el-input v-model="addressData.post_code" />
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8">
                    <el-form-item :label="$t('clients.telephone')">
                        <el-input v-model="addressData.telephone" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.door_code')">
                        <el-input v-model="addressData.door_code" />
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8">
                    <el-form-item :label="$t('clients.apartment_type')">
                        <el-select v-model="addressData.apartment_type" class="w-full">
                            <el-option value="house" :label="$t('clients.house')" />
                            <el-option value="flat" :label="$t('clients.flat')" />
                            <el-option value="two_level_flat" :label="$t('clients.two_level_flat')" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('clients.square_meters')">
                        <el-input v-model="addressData.square_meters" />
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-8">
                    <div>
                        <el-form-item :label="$t('clients.alarm_unblock_code')">
                            <el-input v-model="addressData.unblock_alarm_code" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.alarm_block_code')">
                            <el-input v-model="addressData.block_alarm_code" />
                        </el-form-item>
                    </div>
                    <el-form-item :label="$t('clients.alarm_instructions')">
                        <el-input v-model="addressData.alarm_code_instructions" type="textarea" resize="none" rows="5" />
                    </el-form-item>
                </div>
                <el-form-item :label="$t('clients.check_in_out_radius') + addressData.geofence_radius + 'm'">
                    <el-slider v-model="addressData.geofence_radius" :min="50" :max="500" :step="10" />
                </el-form-item>
            </el-form>

            <GoogleMap
                :coords="{
                    map_lat: addressData.map_lat,
                    map_lng: addressData.map_lng,
                }"
                :geofenceRadius="addressData.geofence_radius"
                :markerDraggable="true"
                @markerDragged="markerMapDragged"
            />
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('adding')" @click="addAddress">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from './addresses.api';

export default {
    components: {
        GoogleMap:    () => import(/* webpackChunkName: "GoogleMapForAddress" */ '@/components/GoogleMapForAddress/GoogleMapForAddress.vue'),
        AutoComplete: () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            clientUuid:  this.$route.params.uuid,
            addressData: {
                title:                   null,
                status:                  null,
                co:                      null,
                post_code:               null,
                city:                    null,
                street:                  null,
                telephone:               null,
                door_code:               null,
                apartment_type:          null,
                square_meters:           null,
                unblock_alarm_code:      null,
                block_alarm_code:        null,
                alarm_code_instructions: null,
                geofence_radius:         200,
                map_lat:                 59.32519065531827,
                map_lng:                 18.06959644453127,
            },
            rules: {
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async addAddress() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('adding');

            try {
                await Api.create(this.clientUuid, this.addressData);
                this.$notify.success({ title: 'Success' });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('adding');
            }
        },

        placeFound(data) {
            this.addressData.post_code = data.postcode;
            this.addressData.city = data.city;
            this.addressData.street = data.street;
            this.addressData.map_lat = data.map_lat;
            this.addressData.map_lng = data.map_lng;
        },

        markerMapDragged({ mapLat, mapLng }) {
            this.addressData.map_lat = mapLat;
            this.addressData.map_lng = mapLng;
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.addressData = {
                geofence_radius: 200,
                map_lat:         59.32519065531827,
                map_lng:         18.06959644453127,
            };
            this.$emit('close');
        },
    },
};
</script>
<style>
    .pac-container {
        display: inherit;
        z-index: 99999 !important;
    }
</style>
